import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faBookmark, faFileAudio
} from '@fortawesome/free-regular-svg-icons';
import { Row, Col, Navbar, Nav, Tab, Toast, Image } from 'react-bootstrap';

// import { useTour } from './context/tour-context';
import KuulaProject from './components/KuulaProject';
// import SiteItem from './components/SiteItem';

import './styles/App.css';
import './styles/TabStyles.css';

var frame_id = 0;
const KuulaPlayerAPI = window.KuulaPlayerAPI;

const site_items = [
	// platinum pager in room 1 (limo) and room 5 (on left-side of table)
	{ id: 1,  hotspot_name: "BC001", img_loc: "BC001.png", "title": "", "description": "", "visible": false },
	{ id: 2,  hotspot_name: "BC002", img_loc: "BC002.png", "title": "", "description": "", "visible": false },
	{ id: 3,  hotspot_name: "BC003", img_loc: "BC003.png", "title": "", "description": "", "visible": false },
	{ id: 4,  hotspot_name: "BC004", img_loc: "BC004.png", "title": "", "description": "", "visible": false },
	{ id: 5,  hotspot_name: "BC005", img_loc: "BC005.png", "title": "", "description": "", "visible": false },
	{ id: 6,  hotspot_name: "BC006", img_loc: "BC006.png", "title": "", "description": "", "visible": false },
	{ id: 7,  hotspot_name: "BC007", img_loc: "BC007.png", "title": "", "description": "", "visible": false },
	{ id: 8,  hotspot_name: "BC008", img_loc: "BC008.png", "title": "", "description": "", "visible": false },
	{ id: 9,  hotspot_name: "BC009", img_loc: "BC009.png", "title": "", "description": "", "visible": false },
	{ id: 10, hotspot_name: "BC010", img_loc: "BC010.png", "title": "", "description": "", "visible": false },
	{ id: 11, hotspot_name: "BC011", img_loc: "BC011.png", "title": "", "description": "", "visible": false },
	{ id: 12, hotspot_name: "BC012", img_loc: "BC012.png", "title": "", "description": "", "visible": false },
	{ id: 13, hotspot_name: "BC013", img_loc: "BC013.png", "title": "", "description": "", "visible": false },
	{ id: 14, hotspot_name: "BC014", img_loc: "BC014.png", "title": "", "description": "", "visible": false },
	{ id: 15, hotspot_name: "BC015", img_loc: "BC015.png", "title": "", "description": "", "visible": false },
	{ id: 16, hotspot_name: "BC016", img_loc: "BC016.png", "title": "", "description": "", "visible": false },
	{ id: 17, hotspot_name: "BC017", img_loc: "BC017.png", "title": "", "description": "", "visible": false }
]

export default function App() {
  
	// const [ toastShow, setToastShow ] = useState(false);
	const [ toastList, setToastList ] = useState([]);

	const [ offcanvas, setOffcanvas ] = useState('');
	const [ tabSelected, setTab ] = useState('items');
	
	const [ items, setItems ] = useState([ ...site_items ]);
	const [ itemCount, setItemCount ] = useState(0);

	const [ hotspot, setSelectedHotspot ] = useState('');
	
	function toggleCanvas() {
		if ( offcanvas === '' )
			setOffcanvas('show')
		else 
			setOffcanvas('')
	}

	useEffect(() => {
		
		if ( localStorage.getItem('eggs') ) {
			let count = 0;
			var localEggs = JSON.parse(localStorage.getItem('eggs'));
			var updated_items = localEggs?.map(item => {
				if (item.visible) count++;
				return item;
			})
			setItemCount(count);
			setItems([ ...updated_items ])
		}

		KuulaPlayerAPI.addEventListener("frameloaded", function(e) {
			frame_id = e.frame;
		});

		KuulaPlayerAPI.addEventListener("hotspot", function(e) {
			setSelectedHotspot(e.data.name);
		});

	}, []);

	useEffect(() => {

		if ( hotspot !== '' ) {
			// check if item clicked on
			let new_count = itemCount;
			
			var new_toast = {}
			var new_items = items.map(item => {
				if ( item.hotspot_name === hotspot && !item.visible ) {
					new_count++;
					// add item to toast list
					new_toast = {
						'id': item.id,
						'message': 'Hidden Item Found!',
						'item_name': item.title,
						'img': item.img_loc
					}
					return { ...item, visible: true }
				}
				return { ...item };  
			});

			if ( new_count > itemCount ) {

				setItemCount(new_count);
				setItems([ ...new_items ]);
				localStorage.setItem('eggs', JSON.stringify(new_items))
				setToastList([
					...toastList,
					new_toast
				])

			}
		}

	}, [hotspot])

	function clearFoundItems() {
		localStorage.removeItem('eggs');
		localStorage.setItem('eggs', JSON.stringify([ ...site_items ]));
		setItems([ ...site_items ]);
		setItemCount(0);
		setSelectedHotspot('')
	}

	function removeToastFromList(toast_id) {
		var removeItemIndex = toastList.findIndex(toast => toast.id === toast_id);
		var newToastList = [ ...toastList ];
		newToastList.splice(removeItemIndex, 1);
		setToastList(newToastList);
	}

	return (
		<div className="App">

			<Navbar variant="dark" bg="transparent" fixed="bottom" expand={false} defaultExpanded={false}>
				<Navbar.Toggle className="p-0 border-0" data-toggle="offcanvas" onClick={toggleCanvas} />
				<Navbar.Collapse id="offcanvas_navigation">
					<Tab.Container activeKey={tabSelected} onSelect={(k) => setTab(k)}>
						<Row id="tour-title-row">
							<Col>
								<img src={`images/blank-canvas-logo.png`} alt="Blank Canvas" style={{ width: '150px', float: 'right', borderRadius: '5px' }} className="mx-3" />
								<div>
								<p>Blank Canvas</p>
								</div>
							</Col>
						</Row>
						<Row id="tab-row">
							<Col>
								<Nav justify variant="tabs" defaultActiveKey="rooms" style={{ flexDirection: 'row', maxWidth: '400px' }}>
								<Nav.Item>
									<Nav.Link eventKey="audio">
									<FontAwesomeIcon icon={faFileAudio} />
									</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="items">
									<FontAwesomeIcon icon={faBookmark} /> <span id="items_found">{itemCount}</span>/<span id="items_total">{items.length}</span>
									</Nav.Link>
								</Nav.Item>
								</Nav>
							</Col>
						</Row>
						<Row style={{ margin: 0 }}>
							<Col>
								<Tab.Content>
									<Tab.Pane transition={false} eventKey="audio">
										<iframe title="bc-soundcloud-player" width="100%" height="600" scrolling="no" frameborder="no" allow="autoplay" 
											src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1815049263&color=%23533e37&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>
										<div style={{ fontSize: '10px', color: '#cccccc', lineBreak: 'anywhere', wordBreak: 'normal', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', fontFamily: 'Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif', fontWeight: '100' }}>
											<a href="https://soundcloud.com/blankcanvasmixtape" title="Blank Canvas" target="_blank" rel="noreferrer" style={{ color: '#cccccc', textDecoration: 'none' }}>Blank Canvas</a> · 
											<a href="https://soundcloud.com/blankcanvasmixtape/sets/blank-canvas-soundtrack" title="BLANK CANVAS soundtrack" target="_blank" rel="noreferrer" style={{ color: '#cccccc', textDecoration: 'none' }}>BLANK CANVAS soundtrack</a>
										</div>
									</Tab.Pane>
									<Tab.Pane transition={false} eventKey="items">
										{items.length && (
											<div className="tab-pane-instr">
												Can you find all the Hidden Items?<br /> Clicking the items in the 360 viewer will reveal them here. Hover over the item in the menu for a Hint
												{itemCount > 0 && (
													<div>
														[ <span style={{ fontSize: '.8em', color: '#FFFFFF', cursor: 'pointer' }} onClick={() => { clearFoundItems() }}>Clear Found Items</span> ]
													</div>
												)}
											</div>
										)}
										<div id="items">
											<Row>
												{items?.map((item, idx) => 
													item.visible ? (
														<Col xs={6} sm={4}>
															<Image key={item.id.toString()} id={`item_${item.id}`} src={`images/items/${item.img_loc}`} alt="found item" className="item_thumb" title={item.title} fluid />
														</Col>
													) : (
														<Col xs={6} sm={4}>
															<Image key={item.id.toString()} id={`item_${item.id}`} src={`images/hidden-item.png`} alt="hidden item" className="item_thumb" title={'Hidden Item'} fluid />
														</Col>
													)
												)}
											</Row>
										</div>
									</Tab.Pane>
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</Navbar.Collapse>
			</Navbar>

			<div style={{ position: 'absolute', top: '10px', left: '10px', zIndex: '1000' }}>
				{toastList?.map(toast => {
					return (
						<Toast key={toast.id} onClose={() => removeToastFromList(toast.id)} show={true} delay={7000} autohide>
							<Toast.Header>
								<FontAwesomeIcon icon={faBookmark} className="mr-3" />
								<strong className="mr-auto">{toast.message}</strong>
							</Toast.Header>
							<Toast.Body style={{ textAlign: 'left' }}>
								<img src={'images/items/' +toast.img} className="rounded mr-2" style={{ height: '50px', width: '50px' }} alt="" />
								{toast.item_name}
							</Toast.Body>
						</Toast>
					)
				})}
			</div>

			<KuulaProject />
		
		</div>
	)
}
 