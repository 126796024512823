import React, { useEffect } from 'react';

export default function KuulaProject() {

	useEffect(() => {
		// console.log('window', window)
	}, []);

	return (
		<div>
			<iframe className="ku-embed" 
				title="Blank Canvas Comic | Tillavision"
				frameborder="0" allow="xr-spatial-tracking; gyroscope; accelerometer" allowFullscreen
				src="https://tours.mindpower.dev/share/collection/7XWbq?logo=-1&info=0&fs=0&vr=1&thumbs=-1&inst=0&keys=0"></iframe>
		</div>
	)

}

